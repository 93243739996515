<template>
    <div>
        <div class="left">
            <div class="name">污水处理站</div>
            <div class="select">
                1#污水处理站
            </div>
            <div class="flex button-group">
                <Button></Button>
                <Button :active="selectDate === 'year'">今年</Button>
                <Button :active="selectDate === 'month'">本月</Button>
                <Button :active="selectDate === 'week'">本周</Button>
            </div>
            <div class="ca">2022/10/02-2022/10/0</div>
            <Button :active="true">查询</Button>
            <Button>重置</Button>

        </div>

        <div class="flex" style="gap: 2vw;margin-top: 2vh;">
            <Card title="处理站负荷率" subtitle="（设计负荷150吨/天）">
                <Load />
            </Card>

            <Card title="达标率">
                <Goal />
            </Card>
        </div>

        <div class="flex" style="gap: 2vw;margin-top: 2vh;">
            <Card title="吨水电耗">
                <img src="@/assets/sewage/analysis/chart10.png" style="width:100%" alt="">
            </Card>
            <Card title="吨水药耗">
                <img src="@/assets/sewage/analysis/chart11.png" style="width:100%" alt="">
            </Card>
        </div>
    </div>
</template>

<script>
import Load from './components/load.vue'
import Goal from './components/goal.vue'

export default {
    components: {
        Load,
        Goal,
    }
}
</script>